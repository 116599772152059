<template>
    <div class="sup_content customer-list">
        <div class="sup_page_heading">
            <h2>Website Cancellation Request</h2>
        </div>
        <div class="sup_inner_content">
        </div>
    </div>
</template>

<script>
export default {
    name: 'WebsiteCancellationRequest',
    setup(){

    }
}
</script>

<style>

</style>